/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface ComparisonParams {
  ItemNo?: string;
  EANCode?: string;
  Name?: string;
  Group1?: string;
  Group2?: string;
  /** format: double */
  'Price.Min'?: number;
  /** format: double */
  'Price.Max'?: number;
  /** format: double */
  'StockQty.Min'?: number;
  /** format: double */
  'StockQty.Max'?: number;
  /** format: double */
  'ScanQty.Min'?: number;
  /** format: double */
  'ScanQty.Max'?: number;
  /** format: double */
  'DiffQty.Min'?: number;
  /** format: double */
  'DiffQty.Max'?: number;
  /** format: double */
  'DiffPrice.Min'?: number;
  /** format: double */
  'DiffPrice.Max'?: number;
  /** format: int32 */
  VerifyTaskId?: number;
  State?: __model.ComparisonState[];
  /** format: int32 */
  ComparisonType?: ComparisonTypeComparisonParamsEnum;
  ShowNoDiff?: boolean;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export type ComparisonTypeComparisonParamsEnum =
  1 |
  2 |
  3 |
  4;

export interface ExportParams {
  body?: __model.ExportComparisonQuery;
}

export interface SelectAllParams {
  ItemNo?: string;
  EANCode?: string;
  Name?: string;
  Group1?: string;
  Group2?: string;
  /** format: double */
  'Price.Min'?: number;
  /** format: double */
  'Price.Max'?: number;
  /** format: double */
  'StockQty.Min'?: number;
  /** format: double */
  'StockQty.Max'?: number;
  /** format: double */
  'ScanQty.Min'?: number;
  /** format: double */
  'ScanQty.Max'?: number;
  /** format: double */
  'DiffQty.Min'?: number;
  /** format: double */
  'DiffQty.Max'?: number;
  /** format: double */
  'DiffPrice.Min'?: number;
  /** format: double */
  'DiffPrice.Max'?: number;
  /** format: int32 */
  VerifyTaskId?: number;
  State?: __model.ComparisonState[];
  /** format: int32 */
  ComparisonType?: ComparisonTypeSelectAllParamsEnum;
  /** format: int32 */
  ProjectId?: number;
  ShowNoDiff?: boolean;
}

export type ComparisonTypeSelectAllParamsEnum =
  1 |
  2 |
  3 |
  4;

export interface UpdateSelectionParams {
  body?: __model.UpdateComparisonSelectionQuery;
}

export interface DeleteStockParams {
  body?: __model.DeleteStocksCommand;
}

@Injectable()
export class ComparisonService {
  constructor(private http: HttpClient) {}

  /**
   * Összevetés adatok listázása
   * http://undefined/swagger/swagger-ui.html#!/Comparison/Get
   */
  comparison(params: ComparisonParams): Observable<__model.ListComparisonResult> {
    const queryParamBase = {
      ItemNo: params.ItemNo,
      EANCode: params.EANCode,
      Name: params.Name,
      Group1: params.Group1,
      Group2: params.Group2,
      'Price.Min': params['Price.Min'],
      'Price.Max': params['Price.Max'],
      'StockQty.Min': params['StockQty.Min'],
      'StockQty.Max': params['StockQty.Max'],
      'ScanQty.Min': params['ScanQty.Min'],
      'ScanQty.Max': params['ScanQty.Max'],
      'DiffQty.Min': params['DiffQty.Min'],
      'DiffQty.Max': params['DiffQty.Max'],
      'DiffPrice.Min': params['DiffPrice.Min'],
      'DiffPrice.Max': params['DiffPrice.Max'],
      VerifyTaskId: params.VerifyTaskId,
      State: params.State,
      ComparisonType: params.ComparisonType,
      ShowNoDiff: params.ShowNoDiff,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListComparisonResult>(`/api/project/Comparison`, {params: queryParams});
  }

  /**
   * Összevetés adatok exportálása
   * http://undefined/swagger/swagger-ui.html#!/Comparison/Export
   */
  export(params: ExportParams): Observable<string> {
    const bodyParams = params.body;

    return this.http.post<string>(`/api/project/Comparison/Export`, bodyParams || {});
  }

  /**
   * Összevetés azonosítóinak lekérése kiválasztásra
   * http://undefined/swagger/swagger-ui.html#!/Comparison/SelectAll
   */
  selectAll(params: SelectAllParams): Observable<__model.SelectAllComparisonResult> {
    const queryParamBase = {
      ItemNo: params.ItemNo,
      EANCode: params.EANCode,
      Name: params.Name,
      Group1: params.Group1,
      Group2: params.Group2,
      'Price.Min': params['Price.Min'],
      'Price.Max': params['Price.Max'],
      'StockQty.Min': params['StockQty.Min'],
      'StockQty.Max': params['StockQty.Max'],
      'ScanQty.Min': params['ScanQty.Min'],
      'ScanQty.Max': params['ScanQty.Max'],
      'DiffQty.Min': params['DiffQty.Min'],
      'DiffQty.Max': params['DiffQty.Max'],
      'DiffPrice.Min': params['DiffPrice.Min'],
      'DiffPrice.Max': params['DiffPrice.Max'],
      VerifyTaskId: params.VerifyTaskId,
      State: params.State,
      ComparisonType: params.ComparisonType,
      ProjectId: params.ProjectId,
      ShowNoDiff: params.ShowNoDiff,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.SelectAllComparisonResult>(`/api/project/Comparison/SelectAll`, {params: queryParams});
  }

  /**
   * Kijelölt azonosítók szűrése feladatkiosztás után
   * http://undefined/swagger/swagger-ui.html#!/Comparison/UpdateSelection
   */
  updateSelection(params: UpdateSelectionParams): Observable<__model.UpdateComparisonSelectionResult> {
    const bodyParams = params.body;

    return this.http.post<__model.UpdateComparisonSelectionResult>(`/api/project/Comparison/UpdateSelection`, bodyParams || {});
  }

  /**
   * Készlet adatok törlése
   * http://undefined/swagger/swagger-ui.html#!/Comparison/DeleteStock
   */
  deleteStock(params: DeleteStockParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Comparison/DeleteStock`, bodyParams || {});
  }
}
