import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { NotificationService } from './notification.service';
import { SessionProvider } from './session.provider';
import { ShortcutNavigationError } from '../../shared/classes/shortcut-navigation-error';
import { Router } from '@angular/router';
import { ApiError400, ApiError400DefaultTranslation } from '../../../api/enums/api-errorcode';
import { ApiErrorData } from '../../../api/g/defs/ApiErrorData';
import { JobErrorCode } from '../../../api/enums/job-errorcode';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector, private translator: TranslateService) { }

    handleError(error: any) {
        const unwrappedError = error.promise !== undefined ? error.rejection : error;

        if (unwrappedError instanceof HttpErrorResponse) {
            console.error(`Unexpected API error with status code ${unwrappedError.status}`, unwrappedError.error);
        } else if (unwrappedError instanceof ShortcutNavigationError) {
            const ngZone = this.injector.get<NgZone>(NgZone);
            const router = this.injector.get<Router>(Router);
            ngZone.run(() => unwrappedError.navigationAction(router));
            return;
        } else {
            console.error('An error occurred in the application.', error);
        }

        if (!environment.production) {
            // production környezetben nem használunk debuggert
            // tslint:disable-next-line
            debugger;
        }
    }

    /**
     * Általános hibakezelő logika elkapott hibák kezeléséhez.
     * Ha adunk át hibaüzenetet a függvénynek, akkor az egy modális dialógusablak formájában megjelenítésre kerül,
     * egyébként a default általános hibakezelési logika fog lefutni (a 403-as hiba általános kezelésével kiegészítve).
     */
    async handleExpectedError(error: any, message?: string, title?: string, info?: string) {
        const translator = this.injector.get<TranslateService>(TranslateService);
        const notificationService = this.injector.get<NotificationService>(NotificationService);

        if (error && !message) {
            if (error instanceof HttpErrorResponse && error.status === 403) {
                const session = this.injector.get<SessionProvider>(SessionProvider);

                await notificationService.showModalMessage({
                    type: 'error',
                    title: translator.instant('UI.Label.PermissionDenied|Jogosulatlan hozzáférés'),
                    message: translator.instant('UI.Message.PermissionDenied|Ön jelenleg nem rendelkezik a művelet végrehajtásához ' +
                        'szükséges jogosultsággal, mivel feltehetően időközben visszavonták azt.Az üzenet bezárása után átirányítjuk ' +
                        'a kezdőoldalra.')
                });

                throw new ShortcutNavigationError(router => session.navigateToHomePage());
            }

            this.handleError(error);
        }
        return await notificationService.showModalMessage({
            type: 'error',
            title: title || translator.instant('UI.Label.Error|Hiba'),
            message: message || translator.instant('UI.Message.UnexpectedError|Ismeretlen hiba történt. Próbálja újra később, ' +
                'vagy értesítse az üzemeltetőt, ha a hiba továbbra is fennáll!'),
            info: info
        });
    }

    getjobExecutionErrorMessage(error: JobErrorCode): string {
        switch (error) {
            case JobErrorCode.InvalidItemImportFormat:
                return this.translator.instant('UI.JobErrorMessage.InvalidItemImportFormat|Érvénytelen cikkimport formátum!');
            case JobErrorCode.InvalidItemStockFormat:
                return this.translator.instant('UI.JobErrorMessage.InvalidStockImportFormat|Érvénytelen készletimport formátum!');
            case JobErrorCode.AccessDeniedByCloudServer:
                return this.translator.instant('UI.JobErrorMessage.AccessDeniedByCloudServer|A szerver visszautasította a kapcsolatot!');
            case JobErrorCode.CannotConnectToCloudServer:
                return this.translator.instant('UI.JobErrorMessage.CannotConnectToCloudServer|Sikertelen kapcsolódás a felhő szerverhez!');
            case JobErrorCode.InvalidCloudDataFormat:
                return this.translator.instant('UI.JobErrorMessage.InvalidCloudDataFormat|Érvénytelen adatok a felhőből!');
            case JobErrorCode.ProjectInventoryLeaderNotExists:
                return this.translator.instant('UI.JobErrorMessage.ProjectInventoryLeaderNotExists|A megadott leltárvezető érvénytelen!');
            case JobErrorCode.UnknownImportError:
                return this.translator.instant('UI.JobErrorMessage.UnknownImportError|Ismeretlen hiba az importálás során!');
            case JobErrorCode.SQLErrorDuringImport:
                return this.translator.instant('UI.JobErrorMessage.SQLErrorDuringImport|Duplikáció az adatbázisban! Importálás sikertelen.');
            case JobErrorCode.Unknown:
            default:
                return this.translator.instant('UI.JobErrorMessage.Unknown|Váratlan hiba a feldolgozás közben!');
        }
    }

    async handleHTTP400Errors(error: any, errorMessage: string | undefined = undefined) {
        let $error: unknown | undefined;
        let info: string | undefined;

        if (errorMessage === undefined && error instanceof HttpErrorResponse && error.status === 400 && error.error) {
            const apiError = error.error as ApiErrorData;

            switch (ApiError400[apiError.errorCode as keyof typeof ApiError400]) {
                case ApiError400.ConcurrencyConflict:
                    errorMessage = this.translator
                        .instant('UI.Message.ConcurrencyConflict|' + ApiError400DefaultTranslation.ConcurrencyConflict);
                    break;
                case ApiError400.PermissionDenied:
                    errorMessage = this.translator.instant('UI.Message.PermissionDenied|Nincs jogosultsága a művelet végrehajtásához!');
                    break;
                case ApiError400.ParamNotValid:
                    if (apiError.errorDetails !== undefined) {
                        errorMessage = this.translator.instant('UI.Message.ParamNotValid|Érték nem megfelelő:')
                            + apiError.errorDetails[0];
                    }
                    break;
                case ApiError400.ParamNotSpecified:
                    if (apiError.errorDetails !== undefined) {
                        errorMessage = this.translator.instant('UI.Message.ParamNotSpecified|Érték megadása kötelező: ')
                            + apiError.errorDetails[0];
                    }
                    break;
                case ApiError400.ProjectsNotLocked:
                    errorMessage = apiError.errorDetails!.length > 1
                        ? this.translator.instant('UI.Message.ProjectsNotLockedMore|Az alábbi projektek nincsenek lezárva: ')
                        : this.translator.instant('UI.Message.ProjectsNotLockedOne|A projekt nincs lezárva: ');
                    errorMessage += apiError.errorDetails!.join(', ');
                    break;
                case ApiError400.ProjectImportFileIsInvalid:
                    errorMessage = this.translator.instant('UI.Message.ProjectImportFileIsInvalid|Hibás importfájl!');
                    if (apiError.errorDetails && apiError.errorDetails.length > 0) {
                        info = apiError.errorDetails.join("<br />");
                    }
                    break;
                case ApiError400.UserMustBeParticipantToLockProject:
                    errorMessage = this.translator.instant('UI.Message.UserMustBeParticipantToLockProject|Csak akkor zárhatod le a projektet, ha hozzá is vagy rendelve a Személyek menüpontban.');
                    errorMessage += apiError.errorDetails!.join(', ');
                    break;
                case ApiError400.LocationExists:
                    errorMessage = apiError.errorDetails!.length > 1
                        ? this.translator.instant('UI.Message.LocationExistsMore|A következő tárhelyek már léteznek: ')
                        : this.translator.instant('UI.Message.LocationExistsOne|A tárhely már létezik: ');
                    errorMessage += apiError.errorDetails!.join(', ');
                    break;
                case ApiError400.LocationNotExists:
                    errorMessage = apiError.errorDetails!.length > 1
                        ? this.translator.instant('UI.Message.LocationNotExistsMore|Nem létező leltárhelyeket szerettél volna módosítani! Ellenőrizd az LHA-kat és próbálkozz újra.')
                        : this.translator.instant('UI.Message.LocationNotExistsOne|Nem létező leltárhelyet szerettél volna módosítani! Ellenőrizd az LHA-t és próbálkozz újra.');
                    //errorMessage += apiError.errorDetails!.join(', ');
                    break;
                case ApiError400.LocationsWithScanCannotBeDeleted:
                    errorMessage = this.translator.instant('UI.Message.LocationsWithScanCannotBeDeleted|Adatot tartalmazó leltárhelyet nem lehet törölni!');
                    break;
                case ApiError400.LocationsInVerifyTaskCannotBeDeleted:
                    errorMessage = this.translator.instant('UI.Message.LocationsInVerifyTaskCannotBeDeleted|Feladathoz tartozó leltárhelyet nem lehet törölni!');
                    break;
                case ApiError400.EntityNotUnique:
                    errorMessage = this.translator.instant('UI.Message.EntityNotUnique|Egyedi érték megadása szükséges! ');
                    errorMessage += '(' + apiError.errorDetails!.join(', ') + ')';
                    break;
                case ApiError400.StockDataNeededForComparison:
                    errorMessage = this.translator.instant('UI.Message.StockDataNeededForComparison|Kontrollmennyiség nélkül nem végezhető termékösszevetés!');
                    info = this.translator.instant('UI.Message.StockDataNeededForComparison.Info|Használd a Cikkek/Készletimport funkciót');
                    break;
                case ApiError400.ParticipantsDeviceIdNotNull:
                    errorMessage = this.translator.instant('UI.Message.ParticipantsDeviceIdNotNull|A hozzárendelés megszüntetése előtt a PDT azonosító leválasztása szükséges!');
                    break;
            }
        }

        $error = error;
        if ($error) {
            await this.handleExpectedError($error, errorMessage, undefined, info);
        }

        return true;
    }
}
